import './App.css';
import {useEffect} from "react";

function App() {

    useEffect(() => {
        document.title = "pdat"
    })

    return (
        <body>
        <noscript>You need to enable JavaScript to run this app.</noscript>
        <div id="root">
            <main className="main">
                <div className="content">
                    <div className="panel">
                        <div className={"panel-title"}>provozováno společností</div>
                        <div className="panel-subtitle">PDAT s.r.o.</div>
                        <div className="panel-content columns">
                            <div>
                                <div>Topolová 772</div>
                                <div>252 42 Jesenice</div>
                                <div>Česká Republika</div>
                            </div>
                            <div>
                                <div>info@pdat.cz</div>
                            </div>
                            <div>
                                <div><strong>IČ:</strong> 25641468</div>
                                <div><strong>DIČ:</strong> CZ25641468</div>
                                <div>Zapsaná v obchodním rejstříku vedeném u Městského soudu v Praze v
                                    oddílu <strong>B</strong>,
                                    vložce číslo <strong>57279</strong></div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        </body>
    );
}

export default App;
